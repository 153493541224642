.title {
    font-weight: bold;
    color : #e1e1e1;
    text-align: center;
    margin: 2rem auto;
    font-size: 35px;
}

.projects {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.project-card {
    width: 30%;
    max-height: 200px;
    border-radius: 10px;
    margin: 2% auto;
    border: 0.5px solid #d0d0d0;
    transition: transform 0.3s ease;
    overflow: hidden;
}
.project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}
.project-card img {
    width: 100%;
    height: 70%;
    border-radius: 10px 10px 0px 0px;
}
.project-card p {
    color: #e1e1e1;
    font-size: 12px;
    text-align: center;
    margin: 1rem auto;
}
.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
  
.modal-content {
    background-color: #333333;
    padding: 2rem;
    width: 80%;
    max-width: 800px;
    height: 60%;
    overflow-y: auto;
    position: relative;
    border-radius: 5px;
  }

.modal-close {
    position: absolute;
    top: 1%;
    right: 1.3%;
    border: none;
    background-color: transparent;
    font-size: 2rem;
    cursor: pointer;
    color: #E1E1E1;
    padding: 0;
    line-height: 0.6;
    transition: color 0.3s ease;
    border: 0.5px solid #e1e1e1;
    border-radius: 20%;
}
.modal-close:hover {
    color: #ffffff;
    border: 0.5px solid #9f9f9f;
}

.modal-content h3 {
    color: #e1e1e1;
    font-size: 30px;
    text-align: center;
    margin: 1rem auto;
    margin-bottom: 2rem;
}

.desc {

    color: #e1e1e1;
    font-size: 20px;
    text-align: justify;
    margin: 1rem auto;
    margin-top: 2rem;

}
.modal-content hr {
    width: 80%; 
    border: none;
    border-top: 1px solid #e1e1e1;
    margin: 1rem auto;
}


@media (max-width: 1024px) {
    .project-card {
        width: 45%;
    }
    .title {
        font-size: 28px;
    }
    .modal-content h3 {
        font-size: 24px;
    }
    .desc {
        font-size: 18px;
    }
}

@media (max-width: 768px) {
    .project-card {
        width: 90%;
    }
    .title {
        font-size: 24px;
    }
    .modal-content h3 {
        font-size: 20px;
    }
    .desc {
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .title {
        font-size: 20px;
    }
    .modal-content {
        width: 95%;
        padding: 1rem;
    }
    .modal-content h3 {
        font-size: 18px;
    }
    .desc {
        font-size: 14px;
    }
}