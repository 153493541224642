body {
  background-color: #333333;
  background-image: radial-gradient(circle, rgba(255, 255, 255, 0.02) 2%, transparent 20%),
                    radial-gradient(circle, rgba(255, 255, 255, 0.02) 1%, transparent 20%);
  background-position: 0 0, 25px 25px;
  background-size: 50px 50px;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif !important;
  letter-spacing: 1.5px;
}
.App {
  display: flex;
  flex-direction: column;
}
.container {
  margin: auto 16rem;
}

@media (max-width: 1200px) {
  .container {
    margin: auto 8rem;
  }
}

@media (max-width: 992px) {
  .container {
    margin: auto 6rem;
  }
}

@media (max-width: 768px) {
  .container {
    margin: auto 4rem;
  }
}

@media (max-width: 576px) {
  .container {
    margin: auto 0.8rem;
  }
}

/* Personnalisez la barre de défilement pour tous les éléments de la page */
::-webkit-scrollbar {
  width: 12px; /* largeur de la barre de défilement */
}

/* Personnalisez le fond de la barre de défilement */
::-webkit-scrollbar-track {
  background-color: #5e5e5e; /* couleur de fond */
  border-radius: 6px; /* arrondir les angles */
}

/* Personnalisez la couleur de la poignée de la barre de défilement */
::-webkit-scrollbar-thumb {
  background-color: #c4c4c4; /* couleur de la poignée */
  border-radius: 6px; /* arrondir les angles */
}

/* Modifier la couleur de la poignée lorsqu'elle est survolée */
::-webkit-scrollbar-thumb:hover {
  background-color: #ffffff;
  cursor:pointer;
}