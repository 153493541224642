.profile {
  text-align: center;
  padding: 2rem;
}

.profile h1 {
  font-weight: bold;
  color: #e1e1e1;
  text-align: center;
  margin: 1rem auto;
  font-size: 35px;
}

.pdf-container {
  margin: 2rem auto;
  max-width: 800px;
}

.download-btn {
  font-size: 20px;
  text-decoration: none;
  color: #e1e1e1;
  border: 2px solid #e1e1e1;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.download-btn:hover {
  background-color: #e1e1e1;
  color: #333333;
}


@media (max-width: 768px) {
  .profile h1 {
    font-size: 30px;
  }

  .download-btn {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .profile h1 {
    font-size: 25px;
  }

  .download-btn {
    font-size: 16px;
  }
}
